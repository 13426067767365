require('metismenu');
import $ from "jquery";

export function mainuisetup() {
    $(function () {
        $(".preloader").fadeOut();
    });

    $(document).on('click', '.mega-dropdown', function (e) {
        e.stopPropagation()
    });
    // ============================================================== 
    // This is for the top header part and sidebar part
    // ==============================================================  
    var set = function () {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        var inputNewMessage = document.getElementById("inputor");
        
        var topOffset = 70;
        var textBox = 35

        if (width < 1170) {
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
            $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
            $(".sidebartoggler i").addClass("ti-menu");
        } else {
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
            //$(".sidebartoggler i").removeClass("ti-menu");
        }

        var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
        height = height - topOffset;
        if (height < 1) height = 1;
        if (height > topOffset) {
            $(".page-wrapper").css("min-height", (height-90) + "px");
        }

        if(inputNewMessage) textBox = parseInt(inputNewMessage.style.height.split("px").join(""))
        
        if(document.getElementById("chatContent")){
            const hg = width < 879 ? 300 : 250
            document.getElementById("chatContent").style.height = (window.innerHeight - hg - textBox) + 'px'
        }
    };
    $(window).ready(set);
    $(window).on("resize", set);
    $(window).on("collapse-message", set);

    // ============================================================== 
    // Theme options
    // ==============================================================     
    $(".sidebartoggler").off('click').on('click', function () {
        if ($("body").hasClass("mini-sidebar")) {
            $("body").trigger("resize");
            $(".scroll-sidebar, .slimScrollDiv").css("overflow", "hidden").parent().css("overflow", "visible");
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
            //$(".sidebartoggler i").addClass("ti-menu");
        } else {
            $("body").trigger("resize");
            $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
            //$(".sidebartoggler i").removeClass("ti-menu");
        }
    });

    // topbar stickey on scroll
    // $(".fix-header .topbar").stick_in_parent({});


    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").off('click').on('click', function () {
        $("body").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
        $(".nav-toggler i").toggleClass("ti-close");
    });
    $(".sidebartoggler").on('click', function () {
        //$(".sidebartoggler i").toggleClass("ti-menu");
    });
    $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
        $(".app-search").toggle(200);
    });
    // ============================================================== 
    // Right sidebar options
    // ============================================================== 
    $(".right-side-toggle").click(function () {
        $(".right-sidebar").slideDown(50);
        $(".right-sidebar").toggleClass("shw-rside");
    });

    $('.floating-labels .form-control').on('focus blur', function (e) {
        $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    // ============================================================== 
    // Sidebarmenu
    // ============================================================== 
    $(function () {
        $('#sidebarnav').metisMenu();
    });

    // ============================================================== 
    // Resize all elements
    // ============================================================== 
    $("body").trigger("resize");

}

export function commonpagesetup(kind, id) {
    $(".open-panel").on("click", function () {
        $(".chat-left-aside").toggleClass("open-pnl");
        // $(".open-panel i").toggleClass("ti-angle-left");
    });

    $(".chat-right-aside").on("click", function () {
        const isSidebarOpen = $("body").hasClass("show-sidebar");
        if(isSidebarOpen) $(".nav-toggler").click();
    });

    $(".dropdown-toggle").on("click", function () {
        const isSidebarOpen = $("body").hasClass("show-sidebar");
        if(isSidebarOpen) $(".nav-toggler").click();
    });

    $(".navbar-brand").on("click", function () {
        const isSidebarOpen = $("body").hasClass("show-sidebar");
        const isPanelOpen = $(".chat-left-aside").hasClass("open-pnl");
        const isDropdownRightOpen = $("#dropdown-toggle").hasClass("show");

        if(isDropdownRightOpen) $(".#dropdown-toggle").click();
        if(isSidebarOpen) $(".nav-toggler").click();
        if(isPanelOpen) $(".open-panel").click();
    });

    $(".bar-list").on("click", function () {
        const isSidebarOpen = $("body").hasClass("show-sidebar");
        const isPanelOpen = $(".chat-left-aside").hasClass("open-pnl");

        if(isSidebarOpen) {
            $(".nav-toggler").click();
        }

        if(!isPanelOpen) {
            $(".open-panel").click();
        }
    });

    $("#left-inner-scrollbar").on("click", function () {
        $(".open-panel").click();
    });

    $(".panel-toggler").on("click", function () {
        const isSidebarOpen = $("body").hasClass("show-sidebar");
        const isPanelOpen = $(".chat-left-aside").hasClass("open-pnl");
        const isDropdownRightOpen = $("#dropdown-toggle").hasClass("show");

        if(isSidebarOpen && isDropdownRightOpen) {
            $(".#dropdown-toggle").click();
        }

        if(isSidebarOpen && isPanelOpen) {
            $(".open-panel").click();
        }
    });

    if(kind == 'message') {
        const panel = $(".open-panel")

        if(!id) {
            panel.click()
        }
    }
}